.input-prepend {
    border-radius: 0;
    border: 0;
    padding-right: 0;
    background-color: #F7FAFA;

    &.input-prepend-focus {
        background-color: $focusGreen;
    }
}

.form-label {
    font-size: 0.75rem;
    font-weight: 700;
    color: #545454;
}

.form-group {
    margin-bottom: 0;
}

.form-control {
    border: 0;
    color: $gray2 !important;
    height: 100%;
    background-color: #F7FAFA;
    font-size: 0.875rem;
    //-webkit-appearance: none;
    -webkit-border-radius: 0px;

    &:focus {
        outline-color: transparent;
        outline-style: none;
        box-shadow: 0 0 0 transparent;
        background-color: $focusGreen;
    }

    &::placeholder {
        color: $gray1;
        font-size: 0.875rem;
    }
}

textarea {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: $gray5 !important;
}

.input-text {
    height: 55px;
    border-style: solid;
    border-width: 1px !important;
    border-color: $gray5 !important;

    &.error {
        border-color: $red !important;

        & input::placeholder {
            color: $red !important;
            opacity: 1; /* Firefox */
        }

        & input:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $red !important;
        }

        & input::-ms-input-placeholder { /* Microsoft Edge */
            color: $red !important;
        }
    }
}

.input-focus,
textarea:focus {
    border-style: solid;
    border-width: 2px !important;
    border-color: $darkBlue !important;
}


.input-text-focus {
    height: 55px;
}

.form-check-input {
    margin-left: 0;
    margin-right: 0;
}

.continue-btn {
    height: 55px;
    background-color: $lightBlue;
    border-radius: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 500;
    padding-left: 1.5rem;
    padding-right: 1.0625rem;
    color: white;

    svg {
        align-self: center;
    }

    &:hover, &:focus {
        background-color: $mediumBlue !important;
        color: white;
    }
}

.continue-btn--center {
    justify-content: center !important;
}

.expanded-continue-btn {
    margin-top: 50px;
    margin-bottom: 150px;
}

@media (min-width: 991px) {
    .expanded-continue-btn {
        margin-left: 42px;
        margin-right: 42px;
    }
}

@media (min-width: 1200px) {
    .expanded-continue-btn {
        margin-left: 154px;
        margin-right: 154px;
    }
}

.general-error {
    color: $red;
}

.form-error {
    font-size: 0.75rem;
    color: $red;
}

button {
    &:focus, &:active {
        outline: none;
        box-shadow: 0 0 0 transparent;
    }
}

//select list styles
.select-div .input-text::after,
.select-div .input-focus::after {
    content: '\f078';
    font: normal normal normal 17px/1 FontAwesome;
    font-weight: 500;
    position: relative;
    color: #e2e2e2;
    right: 30px;
    top: 16px;
    z-index: 10;
    padding: 0;
    pointer-events: none;
    background-color: transparent;
    width: 0;
}

.select-div .error{
    & select {
        color: $red !important;
    }
    &.input-text::after {
        color: $red;
    }
}


/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
    display: none;
}

.select-div select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

//end select list styles

