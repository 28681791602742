.completion {
    text-align: center;
    padding-bottom: 200px;
}

.completion__container {
    height: 100%;
    min-height: 500px;
}

.title.completion__complete {
    color: #6DD039;
}

.completion__separator {
    border-color: #005288;
    width: 50%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.completion__step-title {
    letter-spacing: 0.17px;
    font-weight: 700;
    font-size: 1.875rem;
    color: #0094C1;
    text-align: left;
    margin-bottom: 18px;
}

#completion__animation {
    width: 98px;
    height: 98px;
}

.completion__reviewing-info {
    letter-spacing: 0.17px;
    font-weight: 700 !important;
    font-size: 1.875rem !important;
    color: #005C93 !important;
    line-height: 35px !important;
    margin-bottom: 65px;
}

.completion__apps {
    margin-top: 30px;
}

.completion__app-title {
    text-align: center;
    margin-top: 25px;

    & > h1 {
        font-size: 1.375rem;
    }
}

.completion__app-desc {
    text-align: center;
    line-height: 1.062rem;
    letter-spacing: 0.09px;
    color: #707070;
}

.completion__app-link {
    text-align: center;
    margin-top: 10px;

    & img {
        max-width: 160px;
    }
}

.completion__app-desc-separator {
    border-color: #A4A4A4;
    width: 100%;
    margin-top: 58px;
    margin-bottom: 70px;
}

.completion__app-android {
    margin-bottom: 10px;
}

.completion__video-tutorials-section--title {
    font-weight: bold;
    letter-spacing: 0.12px;
    font-size: 1.375rem;
    color: #0089B9;
}

.completion__video-tutorial {
    p {
        margin-bottom: 45px;
    }

    > iframe {
        width: 90vw;
        height: calc(188 / 335 * 90vw)
    }
}

.completion__video-tutorial--title {
    font-weight: bold;
    letter-spacing: 0.11px;
    font-size: 1.25rem;
    color: #8DBAE6;
    margin: 0;
}

@media (min-width: 576px) {
    .scaffold-container.completion {
        margin-top: 130px;
        margin-bottom: 150px;
    }

    .completion__video-tutorial {
        > iframe {
            width: 400px;
            height: calc(188 / 335 * 400px)
        }
    }
}

@media (min-width: 768px) {
    .completion__app-title {
        margin-top: 0;
        text-align: left;
    }

    .completion__app-desc {
        text-align: left;
    }

    .completion__app-android {
        margin-bottom: 0;
    }

    .completion__app-link {
        text-align: left;
    }

    .completion__video-tutorial {
        > iframe {
            width: 325px;
            height: calc(188 / 335 * 325px)
        }
    }

    .completion__log-in--forgot-password {
       text-align: left; 
    }
}

@media (min-width: 992px) {
    .completion__video-tutorial {
        margin-top: 0;
    }

    .completion__video-tutorial {
        > iframe {
            width: 355px;
            height: calc(188 / 335 * 355px)
        }
    }
}

@media (min-width: 1200px) {
    .completion__video-tutorial {
        > iframe {
            width: 345px;
            height: calc(188 / 335 * 345px)
        }
    }
}
