.text__sm {
    font-size: 0.8em;
    font-weight: 500;
}

.text__md {
    font-size: 1em;
    font-weight: 500;
}

.text__lg {
    font-size: 1.435em;
    font-weight: 500;
}

@media(min-width: 768px) {
    .text__sm {
        font-size: 1.0em;
    }

    .text__md {
        font-size: 1.2em;
    }

    .text__lg {
        font-size: 1.4em;
    }
}
