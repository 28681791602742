.testimonial__image {
    width: 142px;
    height: 118px;
    margin-bottom: 35px;
}

.testimonial__message {
    font-size: 1.625rem;
    line-height: 2.4375rem;
    letter-spacing: .007rem;
    font-weight: 500;
    color: $darkBlue;
}

.testimonial__separator {
    width: 25%;
    border-color: $darkBlue;
}

.testimonial__name {
    color: $veryDarkBlue;
    font-weight: 500;
    line-height: 1.3125rem;
}

.testimonial__location {
    line-height: 1.3125rem;
    font-weight: 500;
    color: #707070;
}

.testimonial__dot {
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
    text-align: center;
    
    svg.dot-icon:hover circle {
        fill: #0B2B46 !important;
    }
}

