.sign-up-form {
    background-color: white;
    padding: 40px 20px;
}

.sign-up-form__already-signed-up {
    color: $gray3;
    letter-spacing: 0.03px;
    font-weight: 500;
}

.sign-up-form__title {
    font-size: 1.3125rem;
    letter-spacing: 0.0075rem;
    font-weight: 500;
    color: $darkBlue;
    text-align: center;
}

@media(min-width: 300px) {
    .sign-up-form__phone {
        width: 125%;
    }
    
    .sign-up-form__service-line {
        width: 100%;
    }

    .sign-up-form__zip {
        width: 75%;
    }
}

@media(min-width: 400px) {
    .sign-up-form__phone {
        width: 100%;
    }

    .sign-up-form__service-line {
        width: 100%;
    }
    
    .sign-up-form__zip {
        width: 100%;
    }
}

@media(min-width: 576px) {
    .sign-up-form {
        max-width: 409px;
        padding: 30px 45px;
    }
    
    .sign-up-form__phone {
        width: 100%;
    }

    .sign-up-form__service-line {
        width: 100%;
    }

    .sign-up-form__zip {
        width: 100%;
    }
}
