
.background-check__or-separator-line {
    border-top-style: solid;
    border-width: 1px;
    border-color: #d7d3d3;
    width: 100%;
    margin-right: 20px;
    margin-left: 20px;
}

@media (min-width: 576px) {
    .background-check__or-separator-line {
        max-width: 145px;
    }
}