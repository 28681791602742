.dashboard__wrapper {
    background-color: #f4f4f4;
}

.dashboard {
    z-index: 1;
}

.dashboard__row {
    background-color: white;
}

.dashboard__banner {
    background-color: #e2eaf3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 305px;
    text-align: center;
    
    h1 {
        color: $darkBlue;
        font-size: 1.875rem;
        line-height: 2.1875rem;
        font-weight: 800;
    }
    p {
        color: #0B2B46;
        font-size: 0.875rem;
        line-height: 1.375rem;
        font-weight: 400;
        padding-top: 15px;
    }
}

.dashboard__links {
    border-style: solid;
    border-width: 1px;
    border-color: #dddddd;
    height: 616px;
}

.dashboard__link {
    height: 102px;
    font-size: 1.3125rem;
    color: $darkBlue;
    font-weight: 600;
    border-style: solid;
    border-width: 1px;
    border-color: #e2e2e2;
    padding-left: 25px;
    padding-right: 25px;
}

@media (min-width: 768px) {
    .dashboard__row {
        height: 616px;
    }
    .dashboard__links {
        height: 100%;
    }
    .dashboard__banner {
        display: block;
        text-align: left;
        h1 {
            padding-top: 135px;
        }

        p {
            padding-top: 15px;
        }
    }
}