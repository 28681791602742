.video-card__container {
    max-width: 350px;
    height: 482px;
    background-color: white;
}

.videos__header {
    font-size: 2rem;
    letter-spacing: 0.01125rem;
    font-weight: 700;
    color: $darkBlue;
}

.videos__separator {
    width: 40px;
    border-color: $darkBlue;
}

.video-card__video {
    height: 296px;
    background-color: black;
    iframe, object, embed {
        width: 100%;
    }
}

.video-card__separator {
    width: 100px;
    border-color: $darkBlue;
    margin-top: 0;
    margin-bottom: 0;
}

.video-card__footer {
    height: 186px;
    background-color: white;
    border-color: #e2e2e2;
    border-style: solid;
    border-width: 1px;
}

.video-card__msg-box {
    height: 173px;
    width: 80%;
    z-index: 1;
    background-color: white;
    margin-left: 30px;
    position: relative;
    top: -32px;
}

.video-card__msg-box--header {
    height: 63px;
    background-color: white;

    h5 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkBlue;
    }
}

.video-card__msg-box--msg {
    margin-top: 10px;
    font-weight: 400;
    color: #707070;
}

.mt-50 {
    margin-top: 50px;
}
