.footer {
    background-color: #0B2B46;
    font-size: 0.75rem;
    font-weight: 500;
    z-index:1;

    a {
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer__container {
    height: 208px;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
}

.footer__list {
    color: white;
    align-items: center;
}

@media (max-width: 575px) {
    .footer__container {
        justify-content: space-evenly;
    }
    .border-xs-bottom {
        width: 100vw;
        border-color: #475F74;
        border-width: 1px;
        border-bottom-style: solid;
    }
}

@media (min-width: 576px) {
    .footer {
        height: 64px;
    }

    .footer__container {
        flex-direction: row;
        height: 64px;
    }

    .footer__logo {
        min-width: 250px;
    }
    
    .footer__list {
        display: flex;
        align-items: center;
        min-width: 250px;
    }
}
