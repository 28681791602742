.terms {
    margin-top: 55px;
}

.terms__box {
    height: 417px;
    max-height: 417px;
    overflow: scroll;
    border-style: solid;
    border-color: #A4A4A4;
    border-width: 1px;
    background-color: #f7fafa;
    padding-left: 0;

    .terms__box-content {
        padding-top: 43px;
        padding-left: 38px;
        padding-right: 38px;
    }
}

.terms__accept {
    font-weight: 500;
    color: #7C7C7C;
    padding-bottom: 50px;
}

a.terms__download {
    color: #005288;
    margin-bottom: 26px;
    font-weight: 500;
    &:hover{
        color: #00416c !important;
    }
    & svg {
        margin-right: 10px;
    }
}

@media (min-width: 991px) {
    .terms__accept {
        margin-left: -35px !important;
    }
}