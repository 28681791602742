.home__one-col-block--wrapper {
    width: 100vw;
    background-color: #f4f4f4;
    height: 395px;
    position: absolute;
}

.home__one-col-block {
    padding-top: 40px;
}

.home__testimonials {
    margin-top: 85px;
    margin-bottom: 80px;
}

.home__mortgage-field-services--wrapper {
    height: 1840px;
    background-color: #f4f4f4;
    padding-top: 70px;
}

.home__contact-us__wrapper {
    height: 408px;
    background-color: $darkBlue;
    width: 100vw;
}

@media (min-width: 576px) {
    .home__one-col-block--wrapper {
        height: 497px;
    }
}

@media (min-width: 768px) {
    .home__one-col-block--wrapper {
        height: 515px;
    }
}

@media (min-width: 992px) {
    .home__one-col-block--wrapper {
        height: 570px;
    }
    
    .home__testimonials {
        margin-bottom: 100px;
    }
}

@media (min-width: 1200px) {
    .home__mortgage-field-services--wrapper {
        height: 800px;
        padding-top: 116px;
    }
}

@import "./Banner/styles";
@import "./SignUpForm/styles";
@import "./Testimonials/styles";
@import "./OneColumnBlock/styles";
@import "./Videos/styles";
@import "./ContactUs/styles";
