.home__contact-us {
    height: 408px;
    background-color: $darkBlue;
}

.home__contact-us--title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
    letter-spacing: 0.013rem;
    color: white;
    padding-bottom: 28px;
}

.home__contact-us--link {
    background-color: white;
    color: $darkBlue;
    padding: 15px 35px;
    border: 2px solid white;
    font-weight: 500;

    &:hover {
        color: white;
        background-color: $darkBlue;
    }
}

@media (min-width: 576px) {
    .home__contact-us--title {
        font-size: 2.375rem;
        line-height: 2.75rem;
        margin-bottom: 45px;
    }
}