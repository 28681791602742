#home__banner-wrapper {
    background-color: #f4f4f4;
}

.nav-menu {
    height: 75px;
    z-index: 3;
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
}

.nav-menu__simple {
    background-color: white;
}

.nav-menu--border {
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #b0a8a8;
}

.nav-menu__scrolled {
    height: 73px;
    background-color: white;
}

.nav-menu__unauthenticated-become-vendor {
    border-radius: 0;
    border: none;
    font-weight: 500;
    background-color: #8dbae6;
    width: 100%;
    height: 44px;

    &:hover {
        background-color: #7094b8;
    }
}

.nav-menu__login {
    border: 2px solid #0b2b46;
    color: #0b2b46 !important;
    font-weight: 500;
    font-size: 0.75rem;

    &:hover {
        color: #005288 !important;
        background-color: white;
    }
}

.nav-menu__login--dark {
    border: 2px solid #0b2b46;
    color: #0b2b46 !important;
    font-weight: 500;
    font-size: 0.75rem;

    &:hover {
        color: #005288 !important;
        background-color: white;
    }
}

.nav-menu__logout {
    border: 2px solid #0b2b46;
    padding: 5px 18px !important;
    color: #0b2b46 !important;
    font-weight: 500;
    font-size: 0.75rem;
    cursor: pointer;
}

.nav-menu__back-link {
    color: #0E5E96 !important;
    font-weight: 500;
    font-size: 1.125rem;
    cursor: pointer;

    &:hover {
        color: #00416c !important;
        g {
            fill: #00416c !important;
        }
    }
}

.nav-menu__back-caret {
    margin-right: 8px;
    transform: translateY(2px) rotate(180deg);
    cursor: pointer;
}

@media (min-width: 576px) {
    .nav-menu {
        padding-left: 30px;
        padding-right: 30px;
    }

    .nav-menu__simple {
        background-color: transparent;
    }

    .nav-menu__unauthenticated-become-vendor {
        width: initial;
    }
    .nav-menu__login,
    .nav-menu__logout {
        padding: 5px 21px !important;
        font-size: 1rem;
    }
    .nav-menu__logout {
        color: #0b2b46 !important;

        &:hover {
            color: #005288 !important;
            background-color: white;
        }
    }

    .nav-menu__login {
        border: 2px solid white;
        color: white !important;
    }

    .nav-menu__login--dark {
        font-size: 1rem;
    }
}