.home__banner-wrapper {
    background-color: #f4f4f4;
}

.home__banner,
.home__banner *{
    z-index: 1;
}

.home__banner-msg {
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: center;
    color: white;
    font-size: 1.075rem;
    font-weight: 500;
    letter-spacing: 0.0081rem;
    line-height: 1.5rem;
}

.home__banner-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.4375rem;
    letter-spacing: 0.018rem;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.home__banner-sub-msg {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.0625rem;
    letter-spacing: 0.09px;
}

.home__banner-video {
    width: 90vw;
    height: calc(506/900*90vw);
    margin-top: 20px;
}

@media (min-width: 576px) {
    .home__banner-video {
        width: 510px;
        height: calc(506/900*510px);
    }
}

@media (min-width: 768px) {
    .home__banner-msg {
        width: 480px;
    }
    
    .home__banner-video {
        width: 625px;
        height: calc(506/900*625px);
    }
}

@media (min-width: 992px) {
    .home__banner-inner-wrapper {
        height: 591px !important;
        width: 100%;
    }
    .home__banner {
        min-height: 768px;
        position: relative;
        margin-top: -74px;
        height: calc(100vh);
    }

    .home__banner-title {
        font-size: 2.5125rem;
        margin-bottom: 15px;
        line-height: 3.25rem;
    }

    .home__banner-msg {
        text-align: left;
        font-size: 1.4375rem;
        line-height: 2rem;
        margin-top: 0;
        margin-bottom: 30px;
        width: 450px;
    }
    
    .home__banner-video {
        width: 450px;
        height: calc(506/900*450px);
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .home__banner-msg {
        width: 619px;
    }
    
    .home__banner-video {
        width: 619px;
        height: calc(506/900*619px);
    }
}
