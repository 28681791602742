$mediumBlue2: #0E5E96;

.terms-of-use__body {
    padding: 3.125rem 0 9.375rem;

    h2 {
        font-size: 1.875rem;
        color: $mediumBlue2;
        font-weight: 100;
        margin-bottom: 1rem;
    }
    p {
        margin-bottom: 3rem;
        font-weight: 400;
        color: #707070;
    }
}