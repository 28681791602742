.one-col-block {
    padding-top: 55px;
}

.one-col-block__title {
    font-size: 1.625rem;
    letter-spacing: 0.00875rem;
    line-height: 1.875rem;
    font-weight: 100;
    color: $darkBlue;
    text-align: center;
}

.one-col-block__msg {
    line-height: 1.5625rem;
    letter-spacing: 0.006rem;
    text-align: center;
    color: #707070;
    font-weight: 400;
}

.one-col-block__video {
    width: 90vw;
    height: calc(506/900*90vw);
}

@media (min-width: 576px) {
    .one-col-block__video {
        width: 510px;
        height: calc(506/900*510px);
    }
}

@media (min-width: 768px) {
    .one-col-block__video {
        width: 625px;
        height: calc(506/900*625px);
    }
}

@media (min-width: 992px) {
    .one-col-block__title {
        font-size: 1.875rem;
        line-height: 2.1875rem;
    }

    .one-col-block__msg {
        font-size: 1.063rem;
        line-height: 1.675;
        letter-spacing: 0.006rem;
        text-align: center;
        font-weight: 400;
    }

    .one-col-block__video {
        width: 808px;
        height: calc(506/900*808px);
    }
}

@media (min-width: 1200px) {
    .one-col-block__video {
        width: 900px;
        height: calc(506/900*900px);
    }
}
