$mediumBlue2: #0E5E96;
$darkBlue: #005288;

.contact-us__body {
    padding: 3.125rem 0 5rem;

    h3 {
        font-size: 1.5rem;
        color: $mediumBlue2;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 0;
        font-weight: 400;
        color: $gray2;
        line-height: 2rem;
        strong {
            font-weight: 700;
        }
        a {
            color: $gray2;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .contact-block {
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid $darkBlue;
        &:first-of-type {
            border-bottom: 1px solid $darkBlue;
        }
    }
    .icon-image {
        margin-bottom: 1rem;
    }
    @media (min-width: 768px) {
        padding: 3.125rem 0 9.375rem;
        .contact-block {
            border-bottom: 0; 
            &:first-of-type {
                border-bottom: 0; 
                border-right: 1px solid $darkBlue;
            }
        }
    }   
}